import { enableProdMode, LOCALE_ID, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { browserTracingIntegration, init, replayIntegration, setExtras, createErrorHandler, TraceService } from '@sentry/angular';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwiperModule } from 'swiper/angular';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomeModule } from './app/view/home/home.module';
import { AuthModule } from './app/view/auth/auth.module';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { analyticsNavigationProvider } from '@futura/futura-ui/analytics';
import { UtilsService } from './app/core/utils-service/utils.service';
import { futuraInterceptor } from './app/core/interceptor/futura/futura.interceptor';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideLottieOptions } from 'ngx-lottie';
import { SpinnerInitializerService } from './app/components/spinner/spinner-initializer.service';
import { Observable } from 'rxjs';
import { ConfigService } from './app/core/config/config.service';
import { HistoryService, provideFuturaAnalytics } from '@futura/futura-ui';
import { Router } from '@angular/router';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { DatePipe, CommonModule, registerLocaleData } from '@angular/common';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import localeIt from '@angular/common/locales/it';
// import * as _ from 'lodash';

if (environment.production) {
  enableProdMode();
}

// (window as any)._ = _;

init({
  dsn: environment.sentry_dsn,
  enabled: environment.production,
  integrations: [browserTracingIntegration(), replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

setExtras({
  platform: environment.platform,
  production: environment.production,
});

registerLocaleData(localeIt, 'it');

export function initConfig(config: ConfigService): () => Observable<boolean> {
  return () => config.init();
}

export function initSpinner(spinnerInitializerService: SpinnerInitializerService): () => void {
  return () => spinnerInitializerService.init();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      AuthModule,
      HomeModule,
      ReactiveFormsModule,
      CommonModule,
      NgxGoogleAnalyticsModule.forRoot(environment.google_analytics_key),
      NgxGoogleAnalyticsRouterModule,
      FormsModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      SwiperModule,
      ServiceWorkerModule.register('futura-service-worker.js', {
        enabled: environment.production,
        // enabled: true,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      DragDropModule,
      MatIconModule,
      MatCheckboxModule,
      MatDialogModule,
      ProgressbarModule,
      TypeaheadModule
    ),
    provideEnvironmentNgxMask({
      validation: false,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase(initializeApp(environment.firebase), environment.firebase.databaseURL)),
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || environment.locale },
    provideTransloco({
      config: {
        availableLangs: ['en', 'it'],
        defaultLang: 'it',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: true, // disable console warnings
      },
      loader: TranslocoHttpLoader,
    }),
    DatePipe,
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        it: 'it-IT',
      },
      defaultLocale: 'it-IT',
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [HistoryService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSpinner,
      multi: true,
      deps: [SpinnerInitializerService],
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideHttpClient(withInterceptors([futuraInterceptor])),
    provideFuturaAnalytics(UtilsService),
    analyticsNavigationProvider(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
