import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {path: "login", component: AuthLoginComponent},
  // {path: "register", component: AuthRegisterComponent},
  {
    path: 'logout',
    loadComponent: () => import('./auth-logout/auth-logout.component').then(m => m.AuthLogoutComponent),
  },
  { path: 'login', loadComponent: () => import('./auth-v2/auth-v2.component').then(m => m.AuthV2Component) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
