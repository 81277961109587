import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { OtpInputComponent } from '@futura/futura-ui/otp-input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnalyticsButtonDirective } from '@futura/futura-ui/analytics';

@Component({
  selector: 'app-otpdialog',
  templateUrl: './otpdialog.component.html',
  styleUrls: ['./otpdialog.component.scss'],
  standalone: true,
  imports: [TranslocoModule, OtpInputComponent, ReactiveFormsModule, AnalyticsButtonDirective],
})
export class OtpdialogComponent implements OnInit, OnDestroy {
  readonly OTP_LENGTH = 6;
  public readonly formGroup = new FormGroup<{ otp: FormControl<string | null> }>({
    otp: new FormControl<string | null>(null, [Validators.required]),
  });

  readonly RESEND_TIME = 60000; // 1 minute

  wait_until = 0;
  wait_until_interval?: ReturnType<typeof setTimeout>;

  constructor(
    private dialogRef: MatDialogRef<OtpdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { error: string }
  ) {}

  ngOnInit(): void {
    this.wait_until_interval = setInterval(() => {
      this.wait_until = Math.max(this.resendWait(), 0);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.wait_until_interval) clearInterval(this.wait_until_interval);
  }

  sendCode() {
    if (this.formGroup.invalid) {
      return;
    }
    const otp = this.formGroup.get('otp')?.value || null;
    if (!otp || otp.length != this.OTP_LENGTH) return;
    if (isNaN(+otp)) return;

    this.dialogRef.close({ confirm: true, value: otp });
  }

  resend() {
    localStorage.setItem('otp_resend', new Date().getTime().toString());
    this.dialogRef.close('resend');
  }

  resendWait() {
    const otp_resend = localStorage.getItem('otp_resend');
    if (!otp_resend) return 0;

    const otp_resend_time = +otp_resend;
    const now = new Date().getTime();

    const wait_until = Math.floor((this.RESEND_TIME - (now - otp_resend_time)) / 1000);

    if (wait_until <= 0) localStorage.removeItem('otp_resend');
    return wait_until;
  }
}
