import { Userpilot } from 'userpilot';
import { UtilsService } from '../../core/utils-service/utils.service';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { UserDevice } from '../../core/interfaces/user';
import { getDatabase, onValue, ref, set } from '@angular/fire/database';
import { AuthService } from 'src/app/core/auth-service/auth.service';
import { TranslocoService } from '@jsverse/transloco';
import { RequiredUserInfoDialogComponent } from '../../shared/dialogs/required-user-info-dialog/required-user-info-dialog.component';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized, RouterOutlet } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { captureMessage, setUser } from '@sentry/angular';
import moment from 'moment';
import { ConfigService } from '../../core/config/config.service';
import { MigrationService } from 'src/app/core/migration-service/migration.service';
import { catchError, filter, finalize, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subscription, throwError, zip } from 'rxjs';
import { UsersnapService } from '../../core/usersnap/usersnap.service';
import { AliceChatButtonComponent } from '../../shared/alice-v2/components/alice-chat-button/alice-chat-button.component';
import { NgClass } from '@angular/common';
import { SidenavComponent } from '../../shared/sidenav/component/sidenav/sidenav.component';
import { AliceChatComponent } from '../../shared/components/alice-chat/alice-chat.component';
import { TypeformDialogComponent } from 'src/app/shared/dialogs/typeform-dialog/typeform-dialog.component';
import mixpanel from 'mixpanel-browser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const analytics: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const _hsq: any;

// let animations_up = new Array<any>();
// let animations_down = new Array<any>();

// for(let i = 0; i < navbar_elements.length; i++) {
//   const ei = navbar_elements[i];
//   for(let j = i + 1; j < navbar_elements.length; j++) {
//     const ej = navbar_elements[j];
//     animations_up.push(transition(ei + " => " + ej, useAnimation(rotateGlueFromTop)))
//   }
// }

// for(let i = 0; i < navbar_elements.length; i++) {
//   const ei = navbar_elements[i];
//   for(let j = i + 1; j < navbar_elements.length; j++) {
//     const ej = navbar_elements[j];
//     animations_down.push(transition(ej + " => " + ei, useAnimation(rotateGlueFromBottom)))
//   }
// }

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  standalone: true,
  imports: [AliceChatComponent, SidenavComponent, NgClass, RouterOutlet, AliceChatButtonComponent],
})
export class BaseComponent implements OnInit, OnDestroy {
  loaded = false;
  current_route = '';
  user?: User;
  onUserLoaded: EventEmitter<User> = new EventEmitter<User>();
  MAX_USER_DEVICES = 0;
  DAYS_TO_DELETE_DEVICE = 0;
  public hiddenMobileOverflow = false;
  confirm_delete_device = false;
  // * Il BaseComponent è il componente principale che ha come rispettivi figli le varie View della WebApp
  private subscriptions = new Subscription();

  public pageBottomSpace = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiService,
    private dialog: MatDialog,
    private translate: TranslocoService,
    private authService: AuthService,
    private utils: UtilsService,
    private configService: ConfigService,
    private migrationService: MigrationService,
    private usersnapService: UsersnapService
  ) {
    setTimeout(() => {
      this.userService.enablePushNotification();
    }, 5000);

    configService.getUiConfig('_max_user_devices').subscribe(mud => {
      if (mud != 0) this.MAX_USER_DEVICES = mud;
    });
    configService.getUiConfig('_days_delete_device').subscribe(ddd => {
      if (ddd != 0) this.DAYS_TO_DELETE_DEVICE = 2;
    });

    zip(this.userService.getFuturaUser().pipe(tap(user => this.userInitialization(user))), this.utils.getFuturaVersion().pipe(map(v => v.version))).subscribe(
      ([user, version]) => this.userPilotInit(user, '1.19.0') // TODO: Fix version from BE
    );

    // ? Save last url for UTILS
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.utils.lastUrl = events[0].urlAfterRedirects;
        // console.log('previous url', events[0].urlAfterRedirects);
        // console.log('current url', events[1].urlAfterRedirects);
      });
    // Funzione che viene chiamata ad ogni cambiamento di pagina
    router.events.subscribe(val => {
      // Prendendo solo l'url della nuova pagina;
      if (!(val instanceof NavigationEnd)) {
        return;
      }
      this.uiService.showSidenav = false;
      const current_location = location.pathname.split('/').join('');
      //events for Salvo side_nav_use
      this.utils.pushDataLayer({
        eventName: 'side_nav_use',
        search_term: null,
        position: null,
        label: current_location,
        action: null,
        cat1: null,
        cat2: null,
        cat3: null,
        status: null,
        date: null,
        order: null,
      });
      if (val.url == '/' || val.url.startsWith('/?code')) {
        this.uiService.showSpinner = true;
        this.loadUser(true)
          .pipe(
            filter(val => val),
            finalize(() => (this.uiService.showSpinner = false))
          )
          .subscribe(() => {
            if (localStorage.getItem('try_to_navigate') == undefined) {
              this.utils.gotoHomePage();
            } else {
              const try_to_navigate = localStorage.getItem('try_to_navigate')!;

              if (try_to_navigate.includes('p/')) this.utils.gotoHomePage();
              else this.router.navigateByUrl(try_to_navigate);

              localStorage.removeItem('try_to_navigate');
            }
          });
      } else Userpilot.reload();
      if (val.url != val.urlAfterRedirects && val.urlAfterRedirects == '/') location.reload();
      this.current_route = val.url;
    });
    const colors: { [p: string]: string } = this.configService.categoryColors;
    for (const color of Object.entries(colors)) {
      document.documentElement.style.setProperty(`--${color[0]}`, color[1]);
      document.documentElement.style.setProperty(`--${color[0]}-image`, `url('./assets/Subjects/${environment.platform}/${color[0]}.jpg')`);
    }
  }

  private loadUser(from_base = false): Observable<boolean> {
    return this.userService.getFuturaUser(false, from_base).pipe(
      catchError(() => {
        captureMessage('User is not logged');
        this.router.navigate(['logout']).catch(console.error);
        return throwError(() => new Error('User is not logged'));
      }),
      tap(user => {
        this.user = user;
        this.uiService.onUserImageUpdated.next(this.userService.getUserImage(user));

        this.loaded = true;
        this.onUserLoaded.emit(user);

        if (!location.host.includes('localhost') && !localStorage.getItem('logged_with_token')) {
          this.userService.getUserDeviceInfo().subscribe(deviceInfo => this.multiDeviceCheck(user, deviceInfo));
        }
      }),
      tap(user => {
        let language = localStorage.getItem('language') || user.content.language || environment.locale;
        if (language == 'undefined') language = user.content.language || environment.locale;

        if (localStorage.getItem('language') == undefined) {
          localStorage.setItem('language', language);

          user.content.language = language;
        }

        this.userService.setLanguage(language);

        this.translate.selectTranslate('title').subscribe(translate => (document.title = translate));
      }),
      tap(() => {
        const submissionGuid = localStorage.getItem('submissionGuid');
        if (submissionGuid) {
          localStorage.removeItem('submissionGuid');
          this.userService.setContactUsingSubmissionGuid(submissionGuid).subscribe(() => this.userService.syncContact().subscribe());
        }

        this.userService.syncContact().subscribe();
      }),
      switchMap(user => {
        if (this.dialog.openDialogs.length != 0) {
          return of(false);
        }

        if (!this.isUserComplete(user)) {
          this.uiService.showSpinner = false;
          return this.configService.getUiConfig('_typeform_onboarding_link').pipe(
            switchMap(link => {
              if (link)
                return this.dialog
                  .open(TypeformDialogComponent, {
                    data: { link },
                    disableClose: true,
                    backdropClass: 'onboarding-dialogs',
                    panelClass: 'onboarding-panel-dialogs',
                  })
                  .afterClosed()
                  .pipe(
                    map(success => !!success),
                    tap(() => location.reload())
                  );
              else
                return this.dialog
                  .open(RequiredUserInfoDialogComponent, {
                    data: { user },
                    disableClose: true,
                    backdropClass: 'onboarding-dialogs',
                    panelClass: 'onboarding-panel-dialogs',
                  })
                  .afterClosed()
                  .pipe(
                    map(success => {
                      if (success) {
                        return true;
                      } else {
                        location.reload();
                        return false;
                      }
                    })
                  );
            })
          );
        } else {
          return this.utils.getFuturaVersion().pipe(
            switchMap(futuraVersion => {
              this.uiService.showSpinner = false;
              if (user.content.version != futuraVersion.version) {
                this.uiService.showSpinner = false;
                return this.migrationService.openMigrationDialog().pipe(
                  map(success => {
                    if (success) {
                      return true;
                    } else {
                      location.reload();
                      return false;
                    }
                  })
                );
              }

              return of(true);
            })
          );
        }
      })
    );
  }

  private multiDeviceCheck(user: User, deviceInfo: UserDevice) {
    let user_devices = user.content.devices || [];

    const find_device_info = user_devices.find(d => d.uuid == deviceInfo.uuid);

    const device_can_be_deleted = user_devices.find(
      d => d.uuid != deviceInfo.uuid && d.first_login && d.first_login < new Date().getTime() - this.DAYS_TO_DELETE_DEVICE * 24 * 60 * 60 * 1000
    );

    if (find_device_info == undefined) {
      if (user_devices.length >= this.MAX_USER_DEVICES && localStorage.getItem('is_logged') && this.MAX_USER_DEVICES != 0) {
        if (device_can_be_deleted) {
          this.uiService.showSpinner = false;
          if (this.dialog.openDialogs.length != 0) return;
          this.dialog
            .open(ConfirmDialogComponent, {
              data: {
                title: this.translate.translate('utils.multi_devices.remove_one.title'),
                content: this.translate.translate('utils.multi_devices.remove_one.content', {
                  count: this.MAX_USER_DEVICES,
                  device: device_can_be_deleted.device_model || device_can_be_deleted.browser,
                }),
                button_title: this.translate.translate('utils.multi_devices.remove_one.confirm'),
              },
            })
            .afterClosed()
            .pipe(
              switchMap(confirm => {
                if (confirm) {
                  this.uiService.showSpinner = true;
                  this.confirm_delete_device = true;
                  user_devices = user_devices.filter(d => d.uuid != device_can_be_deleted.uuid);
                  user.content.devices = user_devices;
                  return this.userService.updateFuturaUser(user).pipe(tap(() => location.reload()));
                } else {
                  captureMessage("User doesn't want to remove a device");
                }
                return of('');
              })
            )
            .subscribe();
          return;
        } else {
          this.uiService.errorToast({
            title: this.translate.translate('utils.errors.to_many_devices', {
              count: this.MAX_USER_DEVICES,
            }),
          });
          // this.authService.logout();
          captureMessage('User have too many devices');
          this.uiService.showSpinner = false;
          return;
        }
      }

      deviceInfo.last_update = new Date().getTime();
      deviceInfo.first_login = new Date().getTime();
      user_devices.push(deviceInfo);
      user.content.devices = user_devices;
    } else {
      find_device_info.last_update = new Date().getTime();
    }

    this.userService.getFirebaseUser().subscribe(fUser => {
      const db = getDatabase();
      const userPathRef = ref(db, `users/${fUser.uid}/current_device`);
      onValue(userPathRef, async snap => {
        const device = snap.val() as UserDevice;
        if (device && device.uuid != deviceInfo.uuid && localStorage.getItem('is_logged')) {
          this.authService.logout().subscribe();
          this.uiService.errorToast({
            title: this.translate.translate('utils.errors.another_device_logged_in', {
              device_name: device.device_model || device.os || device.browser,
            }),
          });
        }
      });

      set(userPathRef, deviceInfo).catch(console.error);
    });
  }

  isUserComplete(user: User): boolean {
    if ((user.content.nickname || '') == '') return false;
    if ((user.content.name || '') == '') return false;
    if ((user.content.surname || '') == '') return false;

    // if (!user.content.platforms![environment.platform] || user.content.platforms![environment.platform].expected_points == undefined) return false;
    // if (!user.content.platforms![environment.platform] || user.content.platforms![environment.platform].weekly_hours == undefined) return false;
    // if (!user.content.platforms![environment.platform] || user.content.platforms![environment.platform].test_date == undefined) return false;

    return true;
  }

  ngOnInit(): void {
    if (this.user == undefined) this.loadUser().subscribe();

    // Get router start data
    this.route.data.subscribe(data => {
      if (data.redirect) this.utils.gotoHomePage();
    });

    this.subscriptions.add(this.uiService._onShowSidenav.subscribe(newValue => (this.hiddenMobileOverflow = newValue)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getState(outlet: any) {
    return outlet.activatedRouteData.state;
  }

  openGeneralSupport() {
    // console.log("openGeneralSupport");
    this.userService.openSupportDialog();
  }

  private userInitialization(user: User): void {
    try {
      this.utils.gtag('set', 'user_properties', {
        user_uuid: user.instance_id,
        user_email: user.content.email || '',
        user_name: `${user.content.name} ${user.content.surname}`,
        user_phone: `${user.content.prefix}${user.content.phone}`,
        licenses: user.content.licenses?.map(l => l.name) || [],
        vertical: environment.platform,
        language: user.content.language,
        age: moment(moment()).diff(new Date(user.content.birthday), 'year') || undefined,
        is_paid_user: user.content.tags?.is_paid_user || false,
        total_spent: user.content.tags?.total_spent || 0,
        first_payment_date:
          user.content.tags && user.content.tags.first_payment_date ? new Date(+user.content.tags.first_payment_date).toISOString().split('T')[0] : undefined,
      });
    } catch (e) {
      console.log('Error in gtag set user_properties', e);
    }

    if (user.content.contact_id && user.content.email) {
      try {
        _hsq.push([
          'identify',
          {
            email: user.content.email,
          },
        ]);
      } catch (e) {
        console.log('Error in _hsq identify', e);
      }
    }

    try {
      this.utils.gtag('config', environment.google_analytics_key, {
        user_id: user.instance_id,
      });
    } catch (e) {
      console.log('Error in gtag config', e);
    }

    try {
      const birthday = +user.content.birthday;
      analytics.identify(user.instance_id, {
        age: birthday ? moment(moment()).diff(new Date(user.content.birthday), 'year') : undefined,
        avatar: user.content.image.url,
        birthday: birthday ? new Date(user.content.birthday).toISOString() : 0,
        email: user.content.email || '',
        firstName: user.content.name,
        id: user.instance_id,
        lastName: user.content.surname,
        username: user.content.nickname || `${user.content.name}_${user.content.surname}`.toLowerCase(),
        name: `${user.content.name} ${user.content.surname}`,
        phone: `${user.content.prefix}${user.content.phone}`,
        licenses: user.content.licenses?.map(l => l.name) || [],
        vertical: environment.platform,
        language: user.content.language,
        createdAt: new Date(user.created_at).toISOString().split('T')[0],
        is_paid_user: user.content.tags?.is_paid_user || false,
        total_spent: user.content.tags?.total_spent || 0,
        first_payment_date:
          user.content.tags && user.content.tags.first_payment_date ? new Date(+user.content.tags.first_payment_date).toISOString().split('T')[0] : undefined,
      });
    } catch (e) {
      console.log('Error in analytics identify', e);
    }

    try {
      mixpanel.identify(user.instance_id);
    } catch (e) {
      console.log('Error in mixpanel identify', e);
    }

    try {
      setUser({
        id: user.instance_id,
        email: user.content.email || '',
        username: user.content.nickname || `${user.content.name} ${user.content.surname}`,
      });
    } catch {
      console.log('Error in Sentry setUser');
    }

    localStorage.setItem(
      'gtagData',
      JSON.stringify({
        uuid: user.instance_id,
        username: user.content.nickname || `${user.content.name} ${user.content.surname}`,
        phone: `${user.content.prefix}${user.content.phone}`,
        first_login: new Date(user.created_at).toISOString().split('T')[0],
        first_payment_date:
          user.content.tags && user.content.tags.first_payment_date ? new Date(+user.content.tags.first_payment_date).toISOString().split('T')[0] : undefined,
        is_paid_user: user.content.tags?.is_paid_user || false,
        total_spent: user.content.tags?.total_spent || 0,
      })
    );

    this.usersnapService.init(user);
  }

  private userPilotInit(user: User, version: string): void {
    try {
      Userpilot.identify(user.instance_id, {
        email: user.content.email || '',
        name: `${user.content.name} ${user.content.surname}`,
        phone: `${user.content.prefix}${user.content.phone}`,
        licenses: user.content.licenses?.map(l => l.name) || [],
        vertical: environment.platform,
        company: {
          id: environment.platform,
          name: environment.platform,
        },
        language: user.content.language,
        created_at: new Date(user.created_at).toISOString().split('T')[0],
        is_paid_user: user.content.tags?.is_paid_user || false,
        total_spent: user.content.tags?.total_spent || 0,
        first_payment_date:
          user.content.tags && user.content.tags.first_payment_date ? new Date(+user.content.tags.first_payment_date).toISOString().split('T')[0] : undefined,
        version,
      });

      Userpilot.reload();
    } catch {
      console.log('Error in Userpilot identify');
    }
  }
}
