import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/utils-service/utils.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { PrivacyPolicyDialogComponent } from '../../../shared/dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { UserService } from 'src/app/core/user-service/user.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaVerifier } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth-service/auth.service';
import { Component, computed, HostListener, OnInit, signal } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ChangeVerticalDialogComponent } from '../../../shared/dialogs/change-vertical-dialog/change-vertical-dialog.component';
import { extractVerticalFromBaseUrl } from '../../../core/util/vertical.util';
import { isMobile, isTablet } from '@futura/futura-ui/breakpoint';
import { toSignal } from '@angular/core/rxjs-interop';
import { PhoneInputModel, validatePhoneNumber, PhoneInputComponent } from '@futura/futura-ui/phone-input';
import { MatIcon } from '@angular/material/icon';
import { EditableDirective } from '@futura/futura-ui/editable';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { GeneralSectionComponent } from '../../../shared/components/general-section/general-section.component';
import { AsyncPipe } from '@angular/common';
import { AnalyticsButtonDirective } from '@futura/futura-ui/analytics';

@Component({
  selector: 'app-auth-v2',
  templateUrl: './auth-v2.component.html',
  styleUrls: ['./auth-v2.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    EditableDirective,
    MatIcon,
    ReactiveFormsModule,
    PhoneInputComponent,
    TranslocoPipe,
    GeneralSectionComponent,
    AsyncPipe,
    AnalyticsButtonDirective,
  ],
})
export class AuthV2Component implements OnInit {
  readonly AUTH_POINT_LIST_LENGTH = 3;
  readonly AUTH_POINT_LIST = Array(this.AUTH_POINT_LIST_LENGTH)
    .fill(1)
    .map((_, i) => i + 1);

  public authLogo = '';

  public vertical = '';

  public loginActive?: boolean;
  second_attemps = false;
  phoneForm = new FormGroup<{ phone: FormControl<PhoneInputModel | null> }>({
    phone: new FormControl<PhoneInputModel | null>(null, [Validators.required, validatePhoneNumber]),
  });
  captchaValid = false;
  question_count_animation = 0;
  question_count = 35000;
  question_increment_step = 381;
  question_increment_velocity = 20;

  vertical$ = this.utilsService.getVerticalName().pipe(map(v => v.name));

  public tabletMode = signal<boolean>(false);

  protected readonly verticals = toSignal(this.config.getVerticals().pipe(map(verticals => verticals.filter(v => !v.disabled))));
  protected readonly currentVertical = computed<string | null>(() => {
    const verticals = this.verticals();
    if (!verticals) {
      return null;
    }

    const vertical = extractVerticalFromBaseUrl(window.location.origin, verticals);
    if (vertical) {
      vertical.name;
    }
    return null;
  });

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    private userService: UserService,
    private uiService: UiService,
    private utils: UtilsService,
    private config: ConfigService,
    private utilsService: UtilsService
  ) {
    if (localStorage.getItem('is_logged') == 'true') {
      this.utils.gotoHomePage();
      return;
    }
  }

  get isFormValid(): boolean {
    return !this.phoneForm.controls['phone'].invalid;
  }

  ngOnInit(): void {
    this.onResize(window.innerWidth);
    this.userService.getFirebaseUser().subscribe(fuser => {
      if (fuser.isAnonymous) {
        this.uiService.showSpinner = true;
        this.auth.logout(false).subscribe(() => {
          location.reload();
        });
      }
    });

    // Check query params if token is present
    const urlParams = new URLSearchParams(window.location.search);

    const submissionGuid = localStorage.getItem('submissionGuid') || urlParams.get('submissionGuid');

    if (submissionGuid) {
      this.uiService.showSpinner = true;
    }

    // Late check for submissionGuid
    setTimeout(() => {
      if (submissionGuid) {
        this.userService.getInfoFromSubmissionGuid(submissionGuid).subscribe({
          next: res => {
            if (res && res.phone) {
              const phone = parsePhoneNumberFromString(res.phone);
              if (!phone) return;
              this.phoneForm.controls['phone'].setValue({
                countryCode: phone.country || 'it',
                dialCode: '+' + phone.countryCallingCode,
                number: phone.nationalNumber,
              });
              this.login();
            }
          },
          error: () => (this.uiService.showSpinner = false),
          complete: () => (this.uiService.showSpinner = false),
        });
      } else this.uiService.showSpinner = false;
    }, 500);

    const token = urlParams.get('token');

    if (token) {
      // Remove token from url
      window.history.replaceState({}, document.title, window.location.pathname);
      this.uiService.showSpinner = true;
      this.auth.loginWithToken(token);
    }

    this.authLogo = this.config.authLogoUrl;

    this.utils.gtag('set', 'user_properties', {
      vertical: environment.platform,
    });

    setTimeout(() => {
      if (this.utils.isDesktopDevice()) {
        document.getElementById('phone')?.focus();
      }

      let utmPhone = localStorage.getItem('utm_phone') || '';

      if (utmPhone && (utmPhone.length == 9 || utmPhone.length == 10)) utmPhone = '0039' + utmPhone;

      const phone = parsePhoneNumberFromString(utmPhone.replace('00', '+'));
      if (utmPhone) localStorage.removeItem('utm_phone');
      if (utmPhone && phone) {
        this.phoneForm.controls['phone'].setValue({
          countryCode: phone.country || 'it',
          dialCode: '+' + phone.countryCallingCode,
          number: phone.nationalNumber,
        });
        this.login();
      }
    }, 100);

    this.question_increment();

    if ((window as any).recaptchaVerifier) {
      try {
        (window as any).recaptchaVerifier.clear();
        (window as any).recaptchaVerifier.render();
      } catch (error) {
        console.log({ error });
      }
    } else {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(this.auth.auth, 'submit-button', {
        size: 'invisible',
        callback: (response: unknown) => {
          this.captchaValid = true;
          if (this.second_attemps) {
            this.login();
          }
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          this.captchaValid = false;
          this.ngOnInit();
        },
      });
    }

    const login_button = document.getElementById('submit-button')!;
    document.addEventListener('mousemove', e => {
      const rect = login_button.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / login_button.clientWidth) * 100;
      const y = ((e.clientY - rect.top) / login_button.clientHeight) * 100;

      login_button.style.setProperty('--mouseX', x + '%');
      login_button.style.setProperty('--mouseY', y + '%');
    });
  }

  question_increment() {
    const interval = setInterval(() => {
      this.question_count_animation += this.question_increment_step;
      if (this.question_count_animation >= this.question_count && this.question_count != -1) clearInterval(interval);
    }, this.question_increment_velocity);
  }

  login() {
    if (this.loginActive) {
      return;
    }

    this.loginActive = true;
    if (!this.isFormValid) {
      this.uiService.errorToast({ title: 'Il numero di telefono inserito non è valido' });
      this.loginActive = false;
      return;
    }
    const phoneValues = this.phoneForm.controls['phone'].value as {
      countryCode: string;
      dialCode: string;
      number: string;
    };

    const number = phoneValues.number.split(' ').join('');
    const dialCode = phoneValues.dialCode;
    const countryCode = phoneValues.countryCode;

    localStorage.setItem('_phone_info', JSON.stringify({ number, dialCode, countryCode }));

    const completePhone = dialCode + number;
    const submissionGuid = localStorage.getItem('submissionGuid');

    if (submissionGuid) {
      this.auth.updateContactPhonenumber(completePhone, submissionGuid).subscribe();
    }

    this.captchaValid = false;
    this.auth
      .internalLogin()
      .pipe(
        switchMap(() => this.auth.futLogin(dialCode, number, countryCode)),
        finalize(() => (this.loginActive = false))
      )
      .subscribe({
        next: (result: boolean) => {
          setInterval(() => {
            if (!result) location.reload();
          }, 3000);
          this.second_attemps = true;
        },
      });
  }

  privacyPolicy() {
    this.dialog.open(PrivacyPolicyDialogComponent, {
      panelClass: 'fut-mat-dialog-transparent',
      height: '100vh',
      data: { url: 'https://drive.google.com/file/d/1jAyPExGY4FmhFUKsUKffVXFmd1dSeiLw/preview' },
    });
  }

  showFreetrialInfo(): boolean {
    const today = new Date();
    const day_number = today.getDate();

    return day_number >= 7 && day_number <= 12;
  }

  goAboutUs() {
    window.open('https://adt.futura.study/', '_blank');
  }

  goPlatform() {
    window.open('https://adt.futura.study/piattaforma', '_blank');
  }

  goCourses() {
    window.open('https://adt.futura.study/tutti-i-corsi', '_blank');
  }

  public changeVertical(): void {
    this.dialog.open(ChangeVerticalDialogComponent, {
      panelClass: 'fut-dialog-md',
    });
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    // console.log('IsTablet', isTablet(width));
    this.tabletMode.set(isTablet(width) || isMobile(width));
  }
}
