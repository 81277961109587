import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: '500',
    loadComponent: () => import('./view/error-pages/page500.component').then(m => m.Page500Component),
  },
  {
    path: 'p',
    loadChildren: () => import('./view/external-product/external-product.module').then(m => m.ExternalProductModule),
  },
  {
    path: 'google6c72d406b711af94.html',
    loadComponent: () => import('./view/google-verification/google-verification.component').then(m => m.GoogleVerificationComponent),
  },
];

if (environment?.production === false) {
  routes.push({
    path: 'demo',
    loadChildren: () => import('./view/demo/demo.module').then(m => m.DemoModule),
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
